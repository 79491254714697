function initHashChangeHandler() {
  const isWebkit = navigator.userAgent.toLowerCase().includes("webkit");
  const isOpera = navigator.userAgent.toLowerCase().includes("opera");
  const isSupportedBrowser = (isWebkit || isOpera) && document.getElementById && window.addEventListener;

  if (!isSupportedBrowser) return;

  const handleHashChange = () => {
    const id = location.hash.substring(1);
    if (!/^[A-z0-9_-]+$/.test(id)) return;

    const element = document.getElementById(id);
    if (!element) return;

    if (!/^(?:a|select|input|button|textarea)$/i.test(element.tagName)) {
      element.tabIndex = -1;
    }
    element.focus();
  };

  window.addEventListener("hashchange", handleHashChange, false);
}

// Call this when the DOM is ready
document.addEventListener("DOMContentLoaded", initHashChangeHandler);
